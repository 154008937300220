@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,400&display=swap");

* {
  box-sizing: border-box;
}

@media (max-width: 600px) {
  table,
  th,
  tr,
  td {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  table,
  th,
  tr,
  td {
    font-size: 8px;
  }
}

#root {
  height: 100vh;
}

body {
  font-family: "DM Sans", sans-serif;
  min-height: 100vh;
  max-width: 100%;
  margin: 0;
  background-color: #ffffff;
  letter-spacing: 0.075em;
  color: #ffffff;
  text-align: center;
}

#navbar {
  width: 100%;
  max-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #004d40;
  margin: 0 auto;
}

nav a {
  display: flex;
  padding: 16px 8px;
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
}

a:hover {
  color: #82fb8e;
}

header {
  background-color: #004d40;
  height: 100svh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
}

header h1,
h2,
h3 {
  font-weight: 300;
}

header h3 {
  font-style: italic;
}

footer {
  background-color: #ffffff;
  padding: 2em;
}

footer a {
  color: #004d40;
  text-decoration: none;
  transition: transform 0.5s ease;
  padding: 20px;
}

footer a:hover {
  transform: scale(1.1);
  color: #004d40;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  max-width: 1200px;
  margin: 0 auto;
}

.filters-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1% 20%;
  margin-bottom: 2%;
}

.selects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

select {
  background-color: #ffffff;
  box-shadow: 0 0 7px rgba(45, 59, 38, 0.3);
  border-radius: 20px;
  padding: 4px;
  color: #333;
  margin-top: 1em;
}

option {
  color: #333;
}

form {
  min-height: 10vh;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  margin-left: 1%;
  margin-right: 1%;
}

input {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  padding: 10px;
  margin: 1%;
  background-color: #004d40;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 0 0 7px rgba(45, 59, 38, 0.3);
}

::placeholder {
  color: #ffffff;
}

button[type="submit"] {
  background-color: #004d40;
  font-family: "DM Sans", sans-serif;
  border-radius: 0.5em;
  width: 80px;
  padding: 4px;
  margin: 10px;
  box-shadow: 0 0 7px rgba(45, 59, 38, 0.3);
  color: white;
}

button[type="submit"]:hover {
  cursor: pointer;
}

.main-container {
  min-height: 100svh;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 4em;
}

table {
  border-collapse: collapse;
  margin: 0 auto;
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  box-shadow: 0 0 7px rgba(45, 59, 38, 0.3);
}

table tr,
td,
th {
  border: 1px solid #333;
  color: #333;
  padding: 2px;
  word-wrap: break-word;
}

th:hover {
  background-color: #004d40;
  color: white;
}

.table-row a {
  text-decoration: none;
  color: #333;
}

.table-row a:hover {
  text-decoration: none;
}

.table-header-row {
  position: sticky;
  top: 0;
  background-color: white;
}

th {
  user-select: none;
  cursor: pointer;
}

th svg {
  max-width: 10px;
}

th svg.asc {
  transform: rotate(180deg);
}

th svg.desc {
  transform: rotate(0deg);
}

.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#latin {
  font-style: italic;
}

#latin:hover {
  background-color: #004d40;
  color: white;
  cursor: pointer;
}

.dark-green {
  color: #004d40;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  min-height: 100svh;
}

.login-input {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  padding: 10px;
  margin: 1%;
  background-color: #004d40;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 0 0 7px rgba(45, 59, 38, 0.3);
}

.error-message {
  color: red;
  margin: 4px;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  padding: 10px;
  margin: 0 auto;
  min-height: 100svh;
}

.plant-detail-container {
  background-color: #004d40;
  color: #ffffff;
  min-height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plant-detail-container p {
  background-color: #004d40;
  color: #ffffff;
  padding: 4px;
  margin: 0 auto;
}

.plant-details {
  min-width: 200px;
  margin: 0 auto;
  padding: 2%;
  border: 1px solid white;
  border-radius: 5px;
}

.edit-button {
  color: white;
  text-decoration: none;
  padding: 0px 6px 0px 6px;
}

.edit-button:visited {
  color: white;
}

.edit-button:hover {
  color: #82fb8e;
}

.delete-button {
  color: #ffffff;
  text-decoration: none;
  padding: 0px 6px 0px 6px;
}

.delete-button:visited {
  color: white;
}

.delete-button:hover {
  color: red;
}

h3 {
  color: #004d40;
}

.white {
  color: white;
}
.green {
  color: #004d40;
}

.h1--small {
  font-size: 1.5rem;
  font-weight: normal;
}

.checkbox {
  color: #004d40;
  display: flex;
}

.checkbox--input {
  width: 1em;
}

.edit-form__select {
  width: 100%;
  border-radius: 0;
  background-color: #004d40;
  color: white;
  padding: 0.5rem;
  margin: 0;
}

.edit-form__select option {
  color: white;
}
